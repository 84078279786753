import { EStrategyName } from "./enums/EStrategyName";
import { Prediction } from "./Prediction";
import { EPredictionType } from "./UserPredictionRequest";
import { PredictionGridModel } from './../../stores/prediction/datamodels/PredictionGridModel';
import { PredictionSummary } from './../../stores/prediction/datamodels/PredictionSummary';

export class UserFilterResult {
    /**
     *
     */
    constructor(
        public batchId: string,
        public accountId: number,
        public accountName: string,
        public gridData: PredictionGridModel[],
        public predictionSummary: PredictionSummary,
        public strategyName: EStrategyName,
        public predictionType: EPredictionType,
        public durationInDays: number,
        public goalText: string | null,
    ) {
    }
}