import { action, computed, makeObservable, observable, toJS } from "mobx";
import { Prediction } from "../../models/prediction/Prediction";
import { UserPredictionRequest } from "../../models/prediction/UserPredictionRequest";
import { Nullable } from "../../types/Nullable";
import PredictionService from '../../services/prediction/PredictionService';
import { NumbersHelper } from '../../services/helpers/NumbersHelper';
import { PredictionResult } from '../../models/prediction/PredictionResult';
import { EStrategyName } from "../../models/prediction/enums/EStrategyName";
import { PredictionSummary } from "./datamodels/PredictionSummary";
import { PredictionSettings, predictionDuration } from "./datamodels/PredictionSettings";
import { PredictionGridModel } from "./datamodels/PredictionGridModel";
import { UserStore } from "../user/UserStore";
import { Account } from './../../models/Account';
import { MapPoint } from './datamodels/MapPoint';
import { ErrorMessages } from './../../constants/ErrorMessages';
import { PreloadRequest } from './../../models/prediction/PreloadRequest';
import { message } from 'antd';
import { GridFilterOptions } from "./datamodels/GridFilterOptions";

export type PredictionGenericFactors = {
    applicantsFactor: number;
    cpaFactor: number;
}

export type clickRange = "High" | "Low" | "Medium";


export class PredictionStore {

    public userStore: UserStore;

    // ### Properties
    @observable
    public goalType = "manual";
    
    @observable
    public autoOptimize = "";

    public goalText: null | string = null;

    @observable
    public userPredictionRequest: Nullable<UserPredictionRequest>;

    @observable
    public predictionResult: Nullable<PredictionResult>;

    @observable
    public filteredPredictions: Prediction[] = [];

    @observable
    public account: Nullable<Account>;

    @observable
    public activeCampaignStrategy: Nullable<EStrategyName>;

    @observable
    public predictionSettings: PredictionSettings = new PredictionSettings(30);

    @observable
    public defaultPredictionSettings: PredictionSettings = new PredictionSettings(30);

    @observable
    public gridFilterOptions: GridFilterOptions = new GridFilterOptions({}, {});



    /**
     * Ctor
     */
    constructor(userStore: UserStore) {
        makeObservable(this);
        this.userStore = userStore;
    }

    // ### Actions
    @action
    public async getPredictionsAndBuildState(predictionRequest: UserPredictionRequest, account: Account, 
        goalType: string, autoOptimize: string, goalText: string | null): Promise<PredictionResult> {
        // start with random number, different than null to start iteration
        let _predictionResultPaginationIndex: number | null = -1;
        let _predictions: Prediction[] = [];
        let predictionResult: PredictionResult | null = null;

        while (_predictionResultPaginationIndex !== null) {
            const _predictionResult = await this.userStore.MakeAuthorizedHttpRequest(async () => {
                return await PredictionService.GetPrediction(predictionRequest);
            })
            if (!_predictionResult) throw new Error(ErrorMessages.PredictionResultNull);

            _predictions = [..._predictions, ..._predictionResult.predictions];
            _predictionResultPaginationIndex = _predictionResult.paginationIndex;
            predictionRequest.paginationIndex = _predictionResult.paginationIndex;
            predictionResult = _predictionResult;
        }

        if (!predictionResult) {
            throw new Error(ErrorMessages.PredictionResultNull);
        }
        predictionResult.predictions = _predictions;

        this.predictionResult = predictionResult;
        this.userPredictionRequest = predictionRequest;
        this.account = account;
        this.goalType = goalType;
        this.autoOptimize = autoOptimize;
        this.goalText = goalText;
        this.activeCampaignStrategy = predictionResult.appliedStrategy;

        this.filteredPredictions = predictionResult.predictions;

        //TODO: built a notification service... It's too tightly coupled to ant design
        if (predictionResult.messageForUser && goalType == "manual") {
            message.warning(predictionResult.messageForUser, 10 /*seconds*/);
        }

        return predictionResult;
    }

    @action
    async GetListOfReadyAccounts(): Promise<Account[]> {
        const users = await this.userStore.MakeAuthorizedHttpRequest(async () => {
            return await PredictionService.GetListOfReadyAccounts();
        })
        return users || [];
    }

    @action
    async PreloadAccount(preloadRequest: PreloadRequest): Promise<string> {
        const result = await this.userStore.MakeAuthorizedHttpRequest(async () => {
            return await PredictionService.PreloadAccount(preloadRequest)
        });

        if (!result) return "";
        return result;
    }


    @action
    public SetActiveCampaignStrategy(strategyOption: EStrategyName): void {
        this.activeCampaignStrategy = strategyOption;

        this.gridFilterOptions.setUseOldFilterAs(true);
    }


    @action
    public SetPredictionSettings(settings: PredictionSettings): void {
        this.predictionSettings = settings;
    }


    @action
    public SetFilteredPredictions(includedPredictionsAfterFilter: { [key: string]: boolean }) {
        if (!this.predictionResult) {
            throw new Error("no prediction result was found");
        }

        if (includedPredictionsAfterFilter["empty"]) {
            this.filteredPredictions = [];
            return;
        }

        if (includedPredictionsAfterFilter["fullList"]) {
            this.filteredPredictions = this.predictionResult.predictions;
            return;
        }

        this.filteredPredictions = this.predictionResult
            .predictions
            .filter(prediction => includedPredictionsAfterFilter[prediction.jobId.toString()]);
    }


    @action
    public SetDefaultPredictionSettings(cvr: number, cvh: number) {
        if (!this.defaultPredictionSettings.cvr || !this.defaultPredictionSettings.cvh) {
            this.defaultPredictionSettings.cvr = cvr * 100; /*We multiply by 100 to have a percentage here*/
            this.defaultPredictionSettings.cvh = cvh;
        }
    }


    @action
    public setGridFilterOptions(
        filteredPredictionsIdMap: { [key: string]: boolean },
        agGridFilterModel: { [key: string]: any }
    ): void {
        this.gridFilterOptions = new GridFilterOptions(filteredPredictionsIdMap, agGridFilterModel);
    }


    // ### Computed
    @computed
    public get GetPredictionSummary(): PredictionSummary {
        if (!this.predictionResult) throw new Error("There are no job prediction to create a summary");

        let _clicks: number = 0;
        let _applicants: number = 0;
        let _budget: number = 0;
        let totaljobs = 0;

        let _baseApplicants = 0;

        this.filteredPredictions.forEach((prediction: Prediction) => {
            const predictionFactors: PredictionGenericFactors = this.GetPredictionGenericFactors(prediction);
            _clicks += prediction.clicks;
            _applicants += (prediction.applicants * predictionFactors.applicantsFactor);
            _budget += (prediction.applicants * prediction.cpa * predictionFactors.applicantsFactor * predictionFactors.cpaFactor);

            totaljobs += 1;
            _baseApplicants += prediction.applicants;
        });


        // Duration accounted
        const durationFactor = this.GetFactorForPredictionDuration(this.predictionSettings.predictionDuration);
        _clicks *= durationFactor;
        _applicants *= durationFactor;
        _budget *= durationFactor;
        _baseApplicants *= durationFactor;


        // cvr = Conversion rate (clicks to applicants)
        let _cvr = _baseApplicants / _clicks;

        // cvh = Conversion rate (applicants to hires)
        let _cvh = this.predictionSettings.cvh || this.predictionResult.cvh;

        let _hires = _applicants * (_cvh / 100);

        // applicants/cvr is clicks. This is the real value scaled (We can't scale clicks prior to that because it has no factor)
        let _scaledClicks = (_applicants / _cvr); /*cvr = applicants/clicks => clicks = applicants/cvr*/

        // Set cvr if requested in settings
        if (this.IsCvrInputGiven()) {
            // we need the number as ratio -> that's why we divide by 100
            _cvr = this.predictionSettings.cvr! / 100;

            _applicants = _scaledClicks * (this.predictionSettings.cvr! / 100);
            _hires = _applicants * (_cvh / 100);
        }

        const predictionSummary = new PredictionSummary(
            _applicants / _cvr,
            _applicants,
            _cvr,
            _budget,
            _budget / _applicants,
            _budget / _scaledClicks,
            totaljobs,
            _hires,
            _cvh,
            _budget / _hires
        );

        this.SetDefaultPredictionSettings(_cvr, _cvh);
        return predictionSummary;
    }


    @computed
    public get GetJobsDisplayedOnMap(): MapPoint[] {
        if (!this.predictionResult) return [];
        const jobsGroupedByLocation = this.filteredPredictions.reduce((groupedDict: any, current) => {
            const location = `${current.cityText}, ${current.stateShortText}`;

            if ((location in groupedDict) === false) {
                groupedDict[location] = [];
            }
            groupedDict[location].push(current);
            return groupedDict;
        }, {})

        const result: MapPoint[] = [];
        for (let location in jobsGroupedByLocation) {
            const jobs: PredictionGridModel[] = jobsGroupedByLocation[location];
            const n = jobs.length;

            let totalLatitude = 0;
            let totalLongitude = 0;
            let totalLowCompetition = 0;
            let totalHighCompetition = 0;
            let totalApplicants = 0;

            jobs.forEach(job => {
                totalLatitude += job.lat;
                totalLongitude += job.long;
                totalApplicants += job.applicants;
                totalLowCompetition += job.competition.toLowerCase() === "low" ? 1 : 0
                totalHighCompetition += job.competition.toLowerCase() === "high" ? 1 : 0
            })

            const pointOnMap = new MapPoint(
                location,
                totalLatitude / n,
                totalLongitude / n,
                jobs.length,
                totalLowCompetition,
                totalHighCompetition,
                totalApplicants);
            result.push(pointOnMap);
        }

        return result;
    }


    // ### Methods
    public GetGridData(predictions: Prediction[] | null = null): PredictionGridModel[] {
        if (!this.predictionResult) throw new Error("There are no job prediction to create a job prediction grid view");
        if (this.activeCampaignStrategy === undefined) throw new Error("There is no active campaign strategy. Can't retrieve jobs grid");
        return this.MapPredictionsToGridData(predictions || this.predictionResult.predictions);
    }


    private GetPredictionGenericFactors(prediction: Prediction): PredictionGenericFactors {
        switch (this.activeCampaignStrategy) {
            case EStrategyName.HighBid:
                return {
                    applicantsFactor: prediction.highBidsAppsFactor,
                    cpaFactor: prediction.highBidsCPAFactor
                }
            case EStrategyName.Expansions:
                return {
                    applicantsFactor: prediction.expansionsAppsFactor,
                    cpaFactor: prediction.expansionsCPAFactor
                }
            case EStrategyName.HighBidAndExpansions:
                return {
                    applicantsFactor: prediction.highBidsAndExpansionsAppsFactor,
                    cpaFactor: prediction.highBidsAndExpansionsCPAFactor
                }
            case EStrategyName.Normal:
            default:
                return {
                    applicantsFactor: 1,
                    cpaFactor: 1
                }
        }
    }


    private CalculateClickRange(clicks: number): clickRange {
        const lowThreshold = 70;
        const highThresold = 120;

        if (clicks >= lowThreshold && clicks <= highThresold) {
            return "Medium";
        }

        if (clicks < lowThreshold) {
            return "Low";
        }

        return "High";
    }


    private GetFactorForPredictionDuration(duration: predictionDuration) {
        if (duration === 30) {
            return 1;
        }
        else if (duration === 14) {
            return (61.1 / 100);
        }

        //7
        return (34.6 / 100);
    }

    private IsCvrInputGiven(): boolean {
        return !!this.predictionSettings.cvr &&
            NumbersHelper.Round(this.predictionSettings.cvr, 2) !== NumbersHelper.Round(this.defaultPredictionSettings.cvr!, 2);
    }


    // Function that builds the grid data
    private MapPredictionsToGridData(predictions: Prediction[]): PredictionGridModel[] {
        const result: PredictionGridModel[] = [];

        predictions.forEach((prediction: Prediction) => {
            const durationFactor = this.GetFactorForPredictionDuration(this.predictionSettings.predictionDuration);
            const predictionFactors: PredictionGenericFactors = this.GetPredictionGenericFactors(prediction);

            let _applicants = prediction.applicants;
            let _clicks = prediction.clicks;
            let _budget = prediction.cpa * _applicants * predictionFactors.cpaFactor * predictionFactors.applicantsFactor;

            let _cvr = prediction.applicants / _clicks;

            // if CVR requested in settings
            if (this.IsCvrInputGiven()) {
                // we need the number as ratio -> that's why we divide by 100
                _cvr = this.predictionSettings.cvr! / 100;
                _applicants = _clicks * (this.predictionSettings.cvr! / 100);
            }

            // const applicantsFactored = _applicants * predictionFactors.applicantsFactor;
            _applicants *= predictionFactors.applicantsFactor;

            // duration factor
            _applicants *= durationFactor;
            _clicks *= durationFactor;
            _budget *= durationFactor;

            const jobDetails = new PredictionGridModel(
                prediction.jobId,
                prediction.extJobTitleText,
                prediction.externalApplyURL,
                prediction.cityText,
                prediction.stateShortText,
                prediction.category,
                this.CalculateClickRange(_applicants / _cvr),
                prediction.demandFactor,
                prediction.supplyFactor,
                prediction.competition,
                _budget,
                _applicants,
                _cvr,
                prediction.lat,
                prediction.long
            );
            result.push(jobDetails);
        })
        return result;
    }

}