import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Box, Button } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import HorizontalDivider from "../common/horizontal-divider/HorizontalDivider";
import InformationBox from "./components/information-box/InformationBox";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "./../../stores/RootStoreContext";
import VerticalDivider from "../common/vertical-divider/VerticalDivider";
import { useStyles } from "./PredictionPage.styles";
import CampaignStrategy from "./components/campaign-strategy/CampaignStrategy";
import ReportDetails from "./components/report-details/ReportDetails";
import EditIcon from "@material-ui/icons/Edit";
import downlodFilePng from "../../assets/images/file-download-x.png";
import useSetCurrentPage from "../../hooks/useSetCurrentPage";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Routes } from "../../constants/Routes";
import { NumbersHelper } from "../../services/helpers/NumbersHelper";
import { withRouter } from "react-router";
import { EStrategyName } from "../../models/prediction/enums/EStrategyName";
import { StringHelper } from "../../services/StringHelper";
import JobsMap from "./components/jobs-map/JobsMap";
import Settings from "./components/settings-tab/Settings";
import JobsGrid from "./components/jobs-grid/JobsGrid";
import PredictionService from "./../../services/prediction/PredictionService";
import { CreatePredictionsExcelRequest } from "./../../models/prediction/CreatePredictionsExcelRequest";
import { Prediction } from "./../../models/prediction/Prediction";
import { useSnackbar } from "notistack";
import { FileDownloader } from "../../services/FileDownloader";
import { PredictionSummary } from "./../../stores/prediction/datamodels/PredictionSummary";
import { message } from "antd";
import Loader from "./../common/feedback/loader/Loader";
import { AuditPredictionRequest } from "../../models/predictionsAudit/AuditPredictionRequest";
import { UserFilterResult } from "./../../models/prediction/UserFilterResult";
import { CreateExcelFromBatchRequest } from "../../models/prediction/CreateExcelFromBatchRequest";

interface Props extends RouteComponentProps {}

type buttonType = "edit";

export type biddingStrategyType = "normal" | "high";
export type jobExpansionStrategyType = "yes" | "no";

const PredictionPage = (props: Props) => {
    /* Props
     * ################################################
     */
    const { history } = props;

    /* App State
     * ################################################
     */
    const { predictionStore } = useContext(RootStoreContext);

    /* Local State
     * ################################################
     */

    const [isExcelDownloadInProcess, setIsExcelDownloadInProcess] =
        useState<boolean>(false);

    // #region Campaign Strategy state
    const [biddingStrategy, setBiddingStrategy] = useState<biddingStrategyType>(
        predictionStore.activeCampaignStrategy === EStrategyName.HighBid ||
            predictionStore.activeCampaignStrategy ===
                EStrategyName.HighBidAndExpansions
            ? "high"
            : "normal"
    );
    const [jobExpansionStrategy, setJobExpansionStrategy] =
        useState<jobExpansionStrategyType>(
            predictionStore.activeCampaignStrategy ===
                EStrategyName.Expansions ||
                predictionStore.activeCampaignStrategy ===
                    EStrategyName.HighBidAndExpansions
                ? "yes"
                : "no"
        );
    // #endregion

    /* Variables declaration
     * ################################################
     */
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    /* Effect(s)
     * ################################################
     */

    useSetCurrentPage();

    useEffect(() => {
        return function cleanUp() {
            predictionStore.SetFilteredPredictions({ fullList: true });
            // predictionStore.setIcludedJobDictionary({
            //     noneEmpty: true,
            // });
        };
    }, []);

    /* Logic
     * ################################################
     */
    const displayRange = (leftNumber: string, rightNumber: string): string => {
        if (
            !NumbersHelper.isNumber(leftNumber) &&
            !NumbersHelper.isNumber(rightNumber)
        ) {
            return "-";
        }
        if (leftNumber === "0" && rightNumber === "0") {
            return "-";
        }

        return `$${leftNumber} - $${rightNumber}`;
    };

    const editHandler = (e: React.MouseEvent) => {
        history.push(Routes.InputPage);
    };

    const returnNumberOrDashIfNaN = (value: string) => {
        let valueNormalized = Number(value.replace(/[,%&]/gi, ""));
        if (isNaN(valueNormalized)) {
            return "-";
        }
        return value;
    };

    const downloadExcelHandler = async () => {
        if (isExcelDownloadInProcess) {
            message.error("Download already in progress");
            return;
        }

        if (predictionStore.GetPredictionSummary.budget === "0") {
            message.error(
                "You can't download an excel since all the data is filtered out"
            );
            return;
        }

        setIsExcelDownloadInProcess(true);
        enqueueSnackbar("Preparing PPR excel for download", {
            variant: "info",
        });

        const accountId = predictionStore.account!.accountId;
        const accountName = predictionStore.account!.accountName;
        const predictionsGridData = predictionStore.GetGridData(
            predictionStore.filteredPredictions
        );

        const summaryModel = predictionStore.GetPredictionSummary;
        const activeStrategy =
            predictionStore.activeCampaignStrategy as EStrategyName;
        const predictionType =
            predictionStore.userPredictionRequest!.predictionType;
        const durationInDays =
            predictionStore.predictionSettings.predictionDuration;
        const goalText =
            predictionStore.goalText;

        const batchSize = 4000;
        const predictionsArrayLength = predictionsGridData.length;
        const batchGuid = uuidv4();

        for (let i = 0; i < predictionsArrayLength; i += batchSize) {
            let batchEndIndex = i + (batchSize - 1);
            batchEndIndex =
                predictionsArrayLength > batchEndIndex
                    ? batchEndIndex
                    : i + (predictionsArrayLength - i);
            // console.log(batchEndIndex);

            const predictionsBatch = predictionsGridData.slice(
                i,
                batchEndIndex + 1
            );
            const userFilterResult: UserFilterResult = new UserFilterResult(
                batchGuid,
                accountId,
                accountName,
                predictionsBatch,
                summaryModel,
                activeStrategy,
                predictionType,
                durationInDays,
                goalText
            );
            await PredictionService.PredictionBatchUpload(userFilterResult);
        }

        const createExcelFromBatchRequest = new CreateExcelFromBatchRequest(
            batchGuid
        );
        const createPredictionsExcelResponse =
            await PredictionService.CreateExcelFromBatch(
                createExcelFromBatchRequest
            );

        const creationDate = new Date(
            createPredictionsExcelResponse.creationDate
        );

        const fileName = `${accountName}-PPR-${creationDate.getUTCFullYear()}-${
            creationDate.getUTCMonth() + 1
        }-${creationDate.getUTCDate()}-${
            createPredictionsExcelResponse.marginPct
        }`;

        const excelBlob = (
            await axios.get(createPredictionsExcelResponse.excelUrl, {
                responseType: "blob",
            })
        ).data;

        new FileDownloader().DownloadFromBlob(excelBlob, `${fileName}.xlsx`);

        setIsExcelDownloadInProcess(false);
    };

    // const auditPredictions = async () => {
    //     if (predictionStore.GetPredictionSummary.budget === "0") {
    //         return;
    //     }

    //     const accountId = predictionStore.account!.accountId;
    //     const predictionsGridData = predictionStore.GetGridData(
    //         predictionStore.filteredPredictions
    //     );
    //     const summaryModel = predictionStore.GetPredictionSummary;
    //     const activeStrategy =
    //         predictionStore.activeCampaignStrategy as EStrategyName;
    //     const predictionType =
    //         predictionStore.userPredictionRequest!.predictionType;
    //     const durationInDays =
    //         predictionStore.predictionSettings.predictionDuration;

    //     const auditRequest: AuditPredictionRequest = new AuditPredictionRequest(
    //         accountId,
    //         /* predictionsGridData */ [],
    //         summaryModel,
    //         activeStrategy,
    //         predictionType,
    //         durationInDays
    //     );

    //     await PredictionService.AuditPredictions(auditRequest);
    // };

    /* Render
     * ################################################
     */

    if (!predictionStore.GetPredictionSummary) {
        throw new Error("There is no prediction summary calculated");
    }

    if (!predictionStore.predictionResult) {
        return <Redirect to={Routes.InputPage} />;
    }

    return (
        <Box>
            <Box component="section" className={classes.whiteSection} p={6}>
                <Box
                    data-about="PPR"
                    display="flex"
                    justifyContent="space-between">
                    <Box display="flex" data-about="Company Name" width="75%">
                        <Box
                            component="h1"
                            className="text text-main text-blue">
                            Predictive Performance Report:{" "}
                            <Box
                                title={predictionStore.account!.accountName}
                                component="span"
                                className={`text text-main text-blue ${classes.accountTitle}`}
                                ml={1}>
                                {predictionStore.account!.accountName}
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        {isExcelDownloadInProcess ? (
                            <Loader style={{ width: 40, height: 40 }} />
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.actionButton}
                                startIcon={
                                    <img
                                        src={downlodFilePng}
                                        alt="Download icon"
                                    />
                                }
                                onClick={async () => {
                                    try {
                                        const tasks = [];
                                        tasks.push(downloadExcelHandler());
                                        // tasks.push(auditPredictions());
                                        await Promise.all(tasks);
                                    } catch (error) {
                                        console.error(error);
                                        message.error("An error occured");
                                    }
                                }}>
                                Download
                            </Button>
                        )}

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={editHandler}
                            className={classes.actionButton}
                            style={{ marginLeft: "20px" }}
                            startIcon={<EditIcon />}>
                            Edit Goal
                        </Button>
                    </Box>
                </Box>

                <Box mt={7} data-about="goals/settings section" display="flex">
                    <ReportDetails />

                    <VerticalDivider height="110px" mx={12} />

                    <CampaignStrategy
                        biddingStrategy={biddingStrategy}
                        setBiddingStrategy={setBiddingStrategy}
                        jobExpansionStrategy={jobExpansionStrategy}
                        setJobExpansionStrategy={setJobExpansionStrategy}
                    />

                    <VerticalDivider height="110px" mx={12} />

                    <Settings />
                </Box>
            </Box>

            <Box p={6}>
                <section>
                    <Box mt={0} mb={3}>
                        <HorizontalDivider text="Report Summary" />
                    </Box>

                    <Box display="flex" style={{ minHeight: "150px" }}>
                        <Box mr={2} height="100%">
                            <InformationBox
                                title="Jobs"
                                data={[
                                    {
                                        name: "Total",
                                        value: predictionStore
                                            .GetPredictionSummary.jobsAmount,
                                    },
                                ]}
                            />
                        </Box>
                        <Box mr={2} height="100%">
                            <InformationBox
                                title="Performance Prediction"
                                data={[
                                    {
                                        name: "Clicks",
                                        value: returnNumberOrDashIfNaN(
                                            predictionStore.GetPredictionSummary
                                                .clicks
                                        ),
                                    },
                                    {
                                        name: "Applicants",
                                        value: predictionStore
                                            .GetPredictionSummary.applicants,
                                    },
                                    {
                                        name: "Hires",
                                        value: predictionStore
                                            .GetPredictionSummary.hires,
                                    },
                                ]}
                            />
                        </Box>
                        <Box mr={2} height="100%">
                            <InformationBox
                                title="Conversion Rates"
                                data={[
                                    {
                                        name: "Click to app.",
                                        value: returnNumberOrDashIfNaN(
                                            predictionStore.GetPredictionSummary
                                                .conversionRate
                                        ),
                                        explanation:
                                            "The conversion rate between clicks to applicants",
                                        postfix: "%",
                                    },
                                    {
                                        name: "App. to hire",
                                        value: predictionStore
                                            .GetPredictionSummary.cvh,
                                        explanation:
                                            "The conversion rate between applicants to hires",
                                        postfix: "%",
                                    },
                                ]}
                            />
                        </Box>
                        <Box mr={2} height="100%">
                            <InformationBox
                                title="Cost Prediction"
                                data={[
                                    {
                                        name: "Est. Cost",
                                        value: displayRange(
                                            predictionStore.GetPredictionSummary
                                                .budgetLowRange,
                                            predictionStore.GetPredictionSummary
                                                .budget
                                        ),
                                        explanation:
                                            "The estimated total budget cost",
                                    },
                                    {
                                        name: "Avg CPC",
                                        value: displayRange(
                                            predictionStore.GetPredictionSummary
                                                .averageCpcLowRange,
                                            predictionStore.GetPredictionSummary
                                                .averageCpc
                                        ),
                                        explanation: "Cost per click",
                                    },
                                    {
                                        name: "Avg CPA",
                                        value: displayRange(
                                            predictionStore.GetPredictionSummary
                                                .averageCpaLowRange,
                                            predictionStore.GetPredictionSummary
                                                .averageCpa
                                        ),
                                        explanation: "Cost per applicant",
                                    },
                                    {
                                        name: "Avg CPH",
                                        value: displayRange(
                                            predictionStore.GetPredictionSummary
                                                .averageCphLowRange,
                                            predictionStore.GetPredictionSummary
                                                .averageCph
                                        ),
                                        explanation: "Cost per hire",
                                    },
                                ]}
                            />
                        </Box>
                        <Box mr={2} height="100%">
                            <InformationBox
                                title="Campaign Strategy"
                                data={[
                                    {
                                        name: "Bid Level",
                                        value: StringHelper.toTitleCase(
                                            biddingStrategy
                                        ),
                                    },
                                    {
                                        name: "Job Expansion",
                                        value: StringHelper.toTitleCase(
                                            jobExpansionStrategy
                                        ),
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                </section>

                <section>
                    <Box mt={6} mb={3}>
                        <HorizontalDivider
                            text="Jobs' Predictions"
                            noteText="* Cost and performance are based on the high range predictions"
                        />
                    </Box>

                    <Box width="100%">
                        <JobsGrid />
                    </Box>
                </section>

                <section>
                    <Box mt={6} mb={3}>
                        <HorizontalDivider text="Predictions Analysis" />
                    </Box>

                    {/* Charts Section */}
                    <Box display="flex" justifyContent="space-between">
                        <Box
                            height="350px"
                            width="49.4%"
                            id="competitionScoreChart"
                            className="ag-theme-alpine my-chart"
                            mt={2}></Box>
                        <Box
                            height="350px"
                            width="49.4%"
                            id="clickRangeChart"
                            className="ag-theme-alpine my-chart"
                            mt={2}></Box>
                    </Box>
                </section>

                <section>
                    <Box mt={6} mb={3}>
                        <HorizontalDivider text="Location Visual Analysis" />
                    </Box>

                    <Box mt={6} height="550px" width="100%">
                        <JobsMap />
                    </Box>
                </section>
            </Box>
        </Box>
    );
};

export default withRouter(observer(PredictionPage));
