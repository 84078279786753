import { Box, Button, Paper } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useStyles } from "./InputPage.styles";
import useOnLoadAsync from "./../../hooks/useOnLoadAsync";
import { Account } from "../../models/Account";
import { message } from "antd";
import {
    EPredictionType,
    UserPredictionRequest,
} from "../../models/prediction/UserPredictionRequest";
import { RootStoreContext } from "./../../stores/RootStoreContext";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Loader from "../common/feedback/loader/Loader";
import { Routes } from "../../constants/Routes";
import useSetCurrentPage from "../../hooks/useSetCurrentPage";
import { InputPageBuilder } from "./InputPage.builder";
import useKeyboardListener from "./../../hooks/useKeyboardListener";
import { Guid } from "../../services/Guid";
import { useSnackbar } from "notistack";

interface Props extends RouteComponentProps { }

const InputPage = (props: Props) => {
    const { history } = props;

    const { predictionStore, uiStore } = useContext(RootStoreContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const [readyAccounts, setReadyAccounts] = useState<Account[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const calculateInitialState = (forType: "budget" | "applicants") => {
        if (
            !predictionStore.userPredictionRequest ||
            predictionStore.userPredictionRequest.amount === 0
        )
            return "";

        if (
            forType === "budget" &&
            predictionStore.userPredictionRequest.predictionType ===
            EPredictionType.Budget
        ) {
            return predictionStore.userPredictionRequest.amount.toString();
        }
        if (
            forType === "applicants" &&
            predictionStore.userPredictionRequest.predictionType ===
            EPredictionType.Applicants
        ) {
            return predictionStore.userPredictionRequest.amount.toString();
        }

        return "";
    };

    // form
    const [budget, setBudget] = useState<string>(
        calculateInitialState("budget")
    );
    const [applicants, setApplicants] = useState<string>(
        calculateInitialState("applicants")
    );
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(
        predictionStore.account || null
    );
    const [goalType, setGoalType] = useState<string>(
        predictionStore.goalType
    );
    const [autoOptimize, setAutoOptimize] = useState<string>(
        predictionStore.autoOptimize
    );
    const [loader, setLoader] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (!selectedAccount) {
            message.error("Please select an account to make a prediction for");
            return;
        }
        setLoader(true);
        // enqueueSnackbar("Generating your predictions", {
        //     variant: "info"
        // });

        let predictionType = EPredictionType.NoRequest;
        let goalText = null;
        if (budget || applicants) {
            predictionType = budget
                ? EPredictionType.Budget
                : EPredictionType.Applicants;
        }
        else{
            goalText = "Cost Effective";
        }

        let amount: string = budget || applicants || "0";
        
        if (goalType == "autoOptimize") {
            if (autoOptimize == "max") {
                amount = "9999999";
                goalText = "Max Applicants";
                predictionType = EPredictionType.Budget;
            }
            else {
                amount = "0";
                goalText = "Cost Effective";
                predictionType = EPredictionType.NoRequest;
            }
        }

        const userPredictionRequest = new UserPredictionRequest(
            selectedAccount.accountId,
            predictionType,
            Number(amount),
            null
        );
        try {
            const _ = await predictionStore.getPredictionsAndBuildState(
                userPredictionRequest,
                selectedAccount,
                goalType,
                autoOptimize,
                goalText
            );
            history.push(Routes.PredictionPage);
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoader(false);
        }
    };

    const handleClear = () => {
        setApplicants("");
        setBudget("");
        setSelectedAccount(null);
        setGoalType("manual");
        setAutoOptimize("balanced");
    };

    /* Hooks
     * ################################################
     */

    useOnLoadAsync(async () => {
        const accounts = await predictionStore.GetListOfReadyAccounts();
        setReadyAccounts(accounts);
        setLoading(false);
    });

    useSetCurrentPage();

    useKeyboardListener(Guid.NewGuid(), ["alt", "2"], () => {
        history.push(Routes.AdminPreload);
    });

    if (uiStore.applicationLoader) {
        return (
            <Box display="flex" justifyContent="center" mt={10}>
                <Loader style={{ width: "200px", height: "200px" }} />
            </Box>
        );
    }

    const builder = new InputPageBuilder(classes);
    return (
        <Box className={classes.background}>
            <Box className={classes.paperWrapper}>
                <Paper elevation={3} className={classes.paper}>
                    <Box data-about="content-wrapper">
                        {builder.Title(
                            "Campaign Simulator"
                        )}

                        {builder.AccountNameAutocompleteInput(
                            readyAccounts,
                            selectedAccount,
                            setSelectedAccount,
                            handleSubmit,
                            loading
                        )}

                        {builder.GoalSettingSection(
                            budget,
                            setBudget,
                            applicants,
                            setApplicants,
                            handleSubmit,
                            goalType,
                            setGoalType,
                            autoOptimize,
                            setAutoOptimize
                        )}

                        {builder.ButtonsSection(
                            loader,
                            handleSubmit,
                            handleClear
                        )}
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default withRouter(observer(InputPage));
