import React from "react";
import "./AutoOptimizeSwitch.scss";
interface Props {
  autoOptimize: string;
  setAutoOptimize: (value: string) => void;
}

const AutoOptimizeSwitch = (props: Props) => {
  const { autoOptimize, setAutoOptimize } = props;
  return (
    <div className="auto-optimize-toggle">
      <input
        type="checkbox"
        id="auto-optimize-toggle-balanced"
        name="auto-optimize"
        value="balanced"
        checked={autoOptimize === "balanced"}
        onChange={() =>
          setAutoOptimize(autoOptimize === "balanced" ? "max" : "balanced")
        }
      />
      <label htmlFor="auto-optimize-toggle-balanced">
        Cost Effective
      </label>

      <input
        type="checkbox"
        id="auto-optimize-toggle-max"
        name="auto-optimize"
        value="max"
        checked={autoOptimize === "max"}
        onChange={() =>
          setAutoOptimize(autoOptimize === "balanced" ? "max" : "balanced")
        }
      />
      <label htmlFor="auto-optimize-toggle-max">
        Max Applicants
      </label>
    </div>
  );
};
export default AutoOptimizeSwitch;
