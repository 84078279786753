import React, { useContext, useState } from "react";
import { Box, Button, MenuItem, Select } from "@material-ui/core";
import { useStyles } from "./ReportDetails.styles";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AdjustOutlinedIcon from "@material-ui/icons/AdjustOutlined";
import { RootStoreContext } from "../../../../stores/RootStoreContext";
import moment from "moment";
import { NumbersHelper } from "../../../../services/helpers/NumbersHelper";
import { observer } from "mobx-react-lite";
import { EPredictionType } from "../../../../models/prediction/UserPredictionRequest";
import LayoutTopSectionColumn from "../layout-top-section-column/LayoutTopSectionColumn";

interface Props { }

const ReportDetails = (props: Props) => {
    const { predictionStore } = useContext(RootStoreContext);

    const classes = useStyles();

    return (
        <Box data-about="report-details">
            <Box
                component="h6"
                className="text text-blue"
                fontSize={16}
                fontWeight="bold">
                Report Details
            </Box>

            <Box
                data-about="detail boxes"
                display="flex"
                alignItems="center"
                mt={3}>
                <LayoutTopSectionColumn
                    icon={<AdjustOutlinedIcon />}
                    title="Goal">
                    {predictionStore.goalText 
                        ?
                        <Box component="p"
                            className="text text-gray text-numeric"
                            textAlign="center"
                            fontSize={25}>
                            {predictionStore.goalText}
                        </Box>
                        :
                        <Box
                            component="p"
                            className="text text-gray text-numeric"
                            textAlign="center"
                            fontSize={25}>
                            {predictionStore.userPredictionRequest!.amount &&
                                predictionStore.userPredictionRequest!
                                    .predictionType === EPredictionType.Budget
                                ? "$"
                                : ""}
                            {predictionStore.userPredictionRequest!.amount
                                ? NumbersHelper.numberWithCommas(
                                    predictionStore.userPredictionRequest!.amount
                                )
                                : "-"}{" "}
                            <Box component="span" className="text text-gray">
                                {predictionStore.userPredictionRequest!.amount
                                    ? predictionStore.userPredictionRequest!
                                        .predictionType
                                    : ""}
                            </Box>
                        </Box>
                    }
                </LayoutTopSectionColumn>

                <LayoutTopSectionColumn
                    title="Start date"
                    icon={<EventIcon />}
                    padLeft>
                    <Box
                        component="p"
                        className={`text text-gray text-numeric ${classes.dateDiv}`}
                        fontSize={25}>
                        {moment(new Date()).format("DD-MM-YYYY")}
                    </Box>
                </LayoutTopSectionColumn>
            </Box>
        </Box>
    );
};

export default observer(ReportDetails);
